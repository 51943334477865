<template>
    <div class="main_content align-items-center main_selfassesment">
        <div class="top_heading mb_64 mb-4">
            <div class="row justify-content-xl-between align-items-center">
                <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
                    <div class="heading padd_ipad">
                        <h2>Follow-Up Feedback</h2>
                        <h3>Program Manager Dashboard</h3>
                        <div class="my-md-3 my-0">
                            <div class="toggle_switch tab_report">
                                <ul id="myTab" role="tablist" class="nav nav-tabs self_btn_list ff_btn">
                                    <li role="presentation" @click="showSATotal" class="nav-item"><button
                                            id="SA-Aggregate-tab" data-bs-toggle="tab" data-bs-target="#SA-Aggregate"
                                            type="button" role="tab" aria-controls="SA-Aggregate" aria-selected="true"
                                            class="nav-link self_btn_f f_sml_btn active">Self-Assessment</button>
                                    </li>
                                    <li role="presentation" @click="showEETotal" class="nav-item"><button
                                            id="EE-Aggregate-tab" data-bs-toggle="tab" data-bs-target="#EE-Aggregate"
                                            type="button" role="tab" aria-controls="EE-Aggregate" aria-selected="false"
                                            class="nav-link self_btn_f f_sml_btn">Ergo
                                            Eval</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
                    <div class="align-items-center d-flex justify-content-end w-100">
                        <div class="date_toolbar_main">
                            <div class="bg-white date-toolbar region_bar_add">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                                        <div class="">
                                            <div class="bg-white date-toolbar" style="box-shadow:none;">
                                                <div class="row">
                                                    <div class="col-xs-12 col-md-12 align-self-center">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-md-12 col-lg-6">
                                                                <div class="date">
                                                                    <div class="heading_5">From</div>
                                                                    <div
                                                                        class="text_black font_size_14 d-flex align-items-center">
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="17.5" height="17.5"
                                                                                viewBox="0 0 17.5 17.5">
                                                                                <path data-name="Path 131"
                                                                                    d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                                                                    transform="translate(-2.25 -2.25)"
                                                                                    style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.start_date"
                                                                            :format="customFormatter"
                                                                            @selected="changeStartDate"
                                                                            :disabledDates="disabledFutureDates">
                                                                        </datepicker>
                                                                        <p class="min-date-value d-none">
                                                                            {{ listConfig.start_date | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-md-12 col-lg-6">
                                                                <div class="date todate pl_6">
                                                                    <div class="heading_5 from_color">To</div>
                                                                    <div class="
                                                                    text_black
                                                                    font_size_14
                                                                    d-flex
                                                                    align-items-center
                                                                    ">
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="17.5" height="17.5"
                                                                                viewBox="0 0 17.5 17.5">
                                                                                <path data-name="Path 131"
                                                                                    d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                                                                    transform="translate(-2.25 -2.25)"
                                                                                    style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.end_date"
                                                                            :format="customFormatter"
                                                                            @selected="changeEndDate" :disabled="isDisabled"
                                                                            :disabledDates="disabledDates">
                                                                        </datepicker>
                                                                        <p class="max-date-value d-none">
                                                                            {{ listConfig.end_date | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                                        <div class="form-group for_ipad for_top_region">
                                            <v-select :reduce="(region_list) => region_list.id" @input="filterMainData"
                                                :options="region_list" label="name" placeholder="By Region" name="Region"
                                                v-model="listConfig.regionBy" v-validate="'required'"
                                                browserAutocomplete="false" autocomplete="off" />
                                            <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                                {{ errors.first("addUserForm.Region") }}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <a href="javascript:" class="reset_btn" @click="resetDatesMain">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                                xml:space="preserve">
                                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                <g>
                                    <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div class="most_visited self_assesment">
            <div class="row">
                <div class="col-md-12 col-xl-7 col-xxl-6">
                    <div class="row">
                        <!-- Was the Self- Assessment helpful? -->
                        <div class="col-xs-12 col-xl-6 lg-padding mt-4" ref="SelfAssessmentHelpfulDiv">
                            <div class="white_card border_radius_20 h-100 ">
                                <div class="activity_region  p-3 border-bottom tab_report mb_18 pb_14">
                                    <div class="row align-items-center">
                                        <div class="col-10 col-sm-8 align-self-center">
                                            <h2 class="mb_20 mini_width">Was the Self-Assessment helpful?</h2>
                                        </div>
                                        <div v-if="hideSAHelpfulDownloadBtn" class="col-2 col-sm-4 text-end">
                                            <div class="left_margin_5" data-html2canvas-ignore="true">
                                                <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                                                    @click="prepareChart('SelfAssessmentHelpfulDiv', 'Self-Assessment-Helpful', '', '', '', self_assessment_helpful)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16">
                                                        <g data-name="Group 12689">
                                                            <path data-name="Path 114"
                                                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                transform="translate(-3 -3)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Unique" role="tabpanel"
                                        aria-labelledby="Unique-tab">
                                        <div class="activity_region mb_10 px-3 ipad_visited helpful_cards">
                                            <div class="row">
                                                <div class="col-sm-12 align-self-center text-lg-end">
                                                    <div
                                                        class="align-items-md-center align-items-baseline d-flex justify-content-evenly justify-content-xl-between px-lg-5 px-xl-0 cube_margin">
                                                        <div class="d-none visited_pages ipad_visited ">
                                                            <div class="heading">
                                                                <div class="form-group mb-0 by_min">
                                                                    <v-select @input="getSAHelpfulChartData"
                                                                        :reduce="(region_list) => region_list.id"
                                                                        :options="region_list" label="name"
                                                                        placeholder="By Region" name="Region"
                                                                        v-model="listConfig.SAHelpful_regionBy"
                                                                        v-validate="'required'" />
                                                                    <small class="error text-danger"
                                                                        v-show="errors.has('addUserForm.Region')">
                                                                        {{ errors.first("addUserForm.Region") }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- toogleButton -->
                                                        <!-- <div class="tab_report toggle_switch">
                                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                                <li class="nav-item" role="presentation">
                                                                    <button class="nav-link active" id="percent-tab"
                                                                        data-bs-toggle="tab" data-bs-target="#percent"
                                                                        type="button" role="tab" aria-controls="percent"
                                                                        aria-selected="true">%
                                                                    </button>
                                                                </li>
                                                                <li class="nav-item" role="presentation">
                                                                    <button class="nav-link" id="hash-tab"
                                                                        data-bs-toggle="tab" data-bs-target="#hash"
                                                                        type="button" role="tab" aria-controls="hash"
                                                                        aria-selected="false">#
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div> -->
                                                        <div
                                                            class="d-none dropdown calender_dropdown align-self-md-center align-self-baseline">
                                                            <a href="javascript:void(0)" id="dropdownMenuLink1"
                                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                                class="ml_20 left_margin_0 dropdown-toggle" role="button"
                                                                data-bs-auto-close="false">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" viewBox="0 0 16 16">
                                                                    <path data-name="Path 130"
                                                                        d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                        transform="translate(-2 -2)"
                                                                        style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                </svg>
                                                            </a>
                                                            <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                                                aria-labelledby="dropdownMenuLink1">
                                                                <div class="date pl_6">
                                                                    <div
                                                                        class="d-flex justify-content-between align-items-center">
                                                                        <div class="heading_5 font_bold mb_10">From
                                                                        </div>
                                                                        <a href="javascript:void(0)" class="reset_btn"
                                                                            @click="resetDatesSA">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                version="1.1" x="0px" y="0px"
                                                                                viewBox="0 0 1000 1000"
                                                                                enable-background="new 0 0 1000 1000"
                                                                                xml:space="preserve">
                                                                                <metadata> Svg Vector Icons :
                                                                                    http://www.onlinewebfonts.com/icon
                                                                                </metadata>
                                                                                <g>
                                                                                    <path
                                                                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div
                                                                        class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                                        <span class="mr_10">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" viewBox="0 0 16 16">
                                                                                <path data-name="Path 130"
                                                                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                                    transform="translate(-2 -2)"
                                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.SAHelpful_sdate"
                                                                            :format="customFormatter"
                                                                            @selected="changeSAStartDate"
                                                                            :disabledDates="disabledFutureDates">
                                                                        </datepicker>
                                                                        <p class="min-date-value d-none">
                                                                            {{ listConfig.SAHelpful_sdate | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="date pl_6 mt_20">
                                                                    <div class="heading_5 font_bold mb_10">To</div>
                                                                    <div
                                                                        class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                                        <span class="mr_10">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" viewBox="0 0 16 16">
                                                                                <path data-name="Path 130"
                                                                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                                    transform="translate(-2 -2)"
                                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.SAHelpful_edate"
                                                                            :format="customFormatter"
                                                                            @selected="changeSAEndDate"
                                                                            :disabled="isDisabledSAHelpful"
                                                                            :disabledDates="disabledDates"></datepicker>
                                                                        <p class="max-date-value d-none">
                                                                            {{ listConfig.SAHelpful_edate | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="button mt_30">
                                                                    <a href="javascript:" @click="updateSAHelpfulChartData"
                                                                        class="btn btn_primary px_14">Update</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bar_graph mb_34 px-3" v-if="self_assessment_helpful.length">
                                            <bar-chart ref="SA_helpful" :chart-data="SAHelpfuldatacollection"></bar-chart>
                                        </div>
                                        <div class="bar_graph mb_34 px-3" v-else>
                                            <div class="
                                                align-items-center
                                                d-md-flex d-xxl-flex
                                                justify-content-between
                                                w-100
                                                ">
                                                <h2 class="no_data">No Data Found</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Was the 1:1 Eval helpful? -->
                        <div class="col-xs-12 col-xl-6 lg-padding mt-5 mt-xl-4" ref="EvalHelpfulDiv">
                            <div class="white_card border_radius_20 h-100 ">
                                <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
                                    <div class="row align-items-center">
                                        <div class="col-10 col-sm-9 align-self-center">
                                            <h2 class="mb_20">Was the 1:1 Eval helpful?</h2>
                                        </div>
                                        <div v-if="hideEEHelpfulDownloadBtn"
                                            class="col-2 col-sm-3 align-self-center text-end">
                                            <div class="left_margin_5" data-html2canvas-ignore="true">
                                                <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                                                    @click="prepareChart('EvalHelpfulDiv', 'Ergo-Eval-Helpful', '', '', '', ergo_eval_helpful)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16">
                                                        <g data-name="Group 12689">
                                                            <path data-name="Path 114"
                                                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                transform="translate(-3 -3)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Unique" role="tabpanel"
                                        aria-labelledby="Unique-tab">
                                        <div class="activity_region mb_10 px-3 ipad_visited helpful_cards">
                                            <div class="row">
                                                <div class="col-sm-12 align-self-center text-lg-end">
                                                    <div
                                                        class="align-items-md-center align-items-baseline d-flex justify-content-evenly justify-content-xl-between px-lg-5 px-xl-0 cube_margin">
                                                        <div class="d-none visited_pages ipad_visited">
                                                            <div class="heading">
                                                                <div class="form-group mb-0 by_min">
                                                                    <v-select @input="getEEHelpfulChartData"
                                                                        :reduce="(region_list) => region_list.id"
                                                                        :options="region_list" label="name"
                                                                        placeholder="By Region" name="Region"
                                                                        v-model="listConfig.EEHelpful_regionBy"
                                                                        v-validate="'required'" />
                                                                    <small class="error text-danger"
                                                                        v-show="errors.has('addUserForm.Region')">
                                                                        {{ errors.first("addUserForm.Region") }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- toogleButton -->
                                                        <!-- <div class="tab_report toggle_switch">
                                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                                <li class="nav-item" role="presentation">
                                                                    <button class="nav-link active" id="percent-tab"
                                                                        data-bs-toggle="tab" data-bs-target="#percent"
                                                                        type="button" role="tab" aria-controls="percent"
                                                                        aria-selected="true">%
                                                                    </button>
                                                                </li>
                                                                <li class="nav-item" role="presentation">
                                                                    <button class="nav-link" id="hash-tab"
                                                                        data-bs-toggle="tab" data-bs-target="#hash"
                                                                        type="button" role="tab" aria-controls="hash"
                                                                        aria-selected="false">#
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div> -->
                                                        <div class="d-none dropdown calender_dropdown">
                                                            <a href="javascript:void(0)" id="dropdownMenuLink2"
                                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                                class="ml_20 left_margin_0 dropdown-toggle" role="button"
                                                                data-bs-auto-close="false">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" viewBox="0 0 16 16">
                                                                    <path data-name="Path 130"
                                                                        d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                        transform="translate(-2 -2)"
                                                                        style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                </svg>
                                                            </a>
                                                            <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                                                aria-labelledby="dropdownMenuLink2">
                                                                <div class="date pl_6">
                                                                    <div
                                                                        class="d-flex justify-content-between align-items-center">
                                                                        <div class="heading_5 font_bold mb_10">From
                                                                        </div>
                                                                        <a href="javascript:void(0)" class="reset_btn"
                                                                            @click="resetDatesEE">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                version="1.1" x="0px" y="0px"
                                                                                viewBox="0 0 1000 1000"
                                                                                enable-background="new 0 0 1000 1000"
                                                                                xml:space="preserve">
                                                                                <metadata> Svg Vector Icons :
                                                                                    http://www.onlinewebfonts.com/icon
                                                                                </metadata>
                                                                                <g>
                                                                                    <path
                                                                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>


                                                                    <div
                                                                        class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                                        <span class="mr_10">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" viewBox="0 0 16 16">
                                                                                <path data-name="Path 130"
                                                                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                                    transform="translate(-2 -2)"
                                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.EEHelpful_sdate"
                                                                            :format="customFormatter"
                                                                            @selected="changeEEStartDate"
                                                                            :disabledDates="disabledFutureDates">
                                                                        </datepicker>
                                                                        <p class="min-date-value d-none">
                                                                            {{ listConfig.EEHelpful_sdate | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="date pl_6 mt_20">
                                                                    <div class="heading_5 font_bold mb_10">To</div>
                                                                    <div
                                                                        class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                                        <span class="mr_10">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" viewBox="0 0 16 16">
                                                                                <path data-name="Path 130"
                                                                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                                    transform="translate(-2 -2)"
                                                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                            </svg>
                                                                        </span>
                                                                        <datepicker placeholder="MM DD, YYYY"
                                                                            v-model="listConfig.EEHelpful_edate"
                                                                            :format="customFormatter"
                                                                            @selected="changeEEEndDate"
                                                                            :disabled="isDisabledEEHelpful"
                                                                            :disabledDates="disabledDates"></datepicker>
                                                                        <p class="max-date-value d-none">
                                                                            {{ listConfig.EEHelpful_edate | formatDate }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="button mt_30">
                                                                    <a href="javascript:" @click="updateEEHelpfulChartData"
                                                                        class="btn btn_primary px_14">Update</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bar_graph mb_34 px-3" v-if="ergo_eval_helpful.length">
                                            <bar-chart ref="EE_helpful" :chart-data="EEHelpfuldatacollection"></bar-chart>
                                        </div>
                                        <div class="bar_graph mb_34 px-3" v-else>
                                            <div class="
                                                align-items-center
                                                d-md-flex d-xxl-flex
                                                justify-content-between
                                                w-100
                                                ">
                                                <h2 class="no_data">No Data Found</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-xl-5 col-xxl-6 for-notepad mt-5 mt-xl-4 px-4 px-lg-3"
                    ref="ReportedImprovementDiv">
                    <div class="activity_region ">
                        <div class="row align-items-center">
                            <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                                <h2 class="mb_20">Reported Improvement</h2>
                            </div>
                            <div class="d-none col-4 col-sm-4 col-md-4 col-lg-4">
                                <div class="visited_pages">
                                    <div class="heading">
                                        <div class="mb_0 by_min reported_region_mini">
                                            <v-select @input="getReportedImprovementChartData"
                                                :reduce="(region_list) => region_list.id" :options="region_list"
                                                label="name" placeholder="By Region" name="Region"
                                                v-model="listConfig.improvement_regionBy" v-validate="'required'" />
                                            <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                                {{ errors.first("addUserForm.Region") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                                <div v-if="hideReportImprovementDownloadBtn"
                                    class="d-flex justify-content-end align-items-center">
                                    <div data-html2canvas-ignore="true">
                                        <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                                            @click="prepareChart('ReportedImprovementDiv', 'Reported-Improvement', '500px', '311px', 'ReportedImprovementHeight', reported_improvement)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16">
                                                <g data-name="Group 12689">
                                                    <path data-name="Path 114"
                                                        d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                        transform="translate(-3 -3)"
                                                        style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                    <div class="d-none dropdown calender_dropdown">
                                        <a href="javascript:void(0)" id="dropdownMenuLink3" data-bs-toggle="dropdown"
                                            aria-expanded="false" class="ml_20 dropdown-toggle" role="button"
                                            data-bs-auto-close="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16">
                                                <path data-name="Path 130"
                                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                    transform="translate(-2 -2)"
                                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                            </svg>
                                        </a>
                                        <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                            aria-labelledby="dropdownMenuLink3">
                                            <div class="date pl_6">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="heading_5 font_bold mb_10">From</div>
                                                    <a href="javascript:void(0)" class="reset_btn" @click="resetDatesRI">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                                                            y="0px" viewBox="0 0 1000 1000"
                                                            enable-background="new 0 0 1000 1000" xml:space="preserve">
                                                            <metadata> Svg Vector Icons :
                                                                http://www.onlinewebfonts.com/icon </metadata>
                                                            <g>
                                                                <path
                                                                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </div>


                                                <div
                                                    class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                    <span class="mr_10">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </span>
                                                    <datepicker placeholder="MM DD, YYYY"
                                                        v-model="listConfig.improvement_sdate" :format="customFormatter"
                                                        @selected="changeRIStartDate" :disabledDates="disabledFutureDates">
                                                    </datepicker>
                                                    <p class="min-date-value d-none">
                                                        {{ listConfig.improvement_sdate | formatDate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="date pl_6 mt_20">
                                                <div class="heading_5 font_bold mb_10">To</div>
                                                <div
                                                    class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                    <span class="mr_10">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            viewBox="0 0 16 16">
                                                            <path data-name="Path 130"
                                                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                transform="translate(-2 -2)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </svg>
                                                    </span>
                                                    <datepicker placeholder="MM DD, YYYY"
                                                        v-model="listConfig.improvement_edate" :format="customFormatter"
                                                        @selected="changeRIEndDate" :disabled="isDisabledImprovement"
                                                        :disabledDates="disabledDates"></datepicker>
                                                    <p class="max-date-value d-none">
                                                        {{ listConfig.improvement_edate | formatDate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="button mt_30">
                                                <a href="javascript:" class="btn btn_primary px_14"
                                                    @click="updateReportedImprovementChartData">Update</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mapping mt-4 ReportedImprovementHeight">
                        <div class="row align-items-center" v-if="reported_improvement.length">
                            <div class="col-md-7 text-center">
                                <div class="pieIchart_1">
                                    <pie-chart ref="reportedImprovementPieChart"
                                        :chart-data="reportedDataCollection"></pie-chart>
                                </div>
                            </div>
                            <div class="col-md-5 align-self-center" v-if="reported_improvement">
                                <div class="row mb_32 justify-content-end">
                                    <div class="col-9 text-end">
                                        <div class="tab_report toggle_switch" data-html2canvas-ignore="true">
                                            <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="percent-tab2" data-bs-toggle="tab"
                                                        data-bs-target="#percent2" type="button" role="tab"
                                                        aria-controls="percent" aria-selected="true">%</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="hash-tab2" data-bs-toggle="tab"
                                                        data-bs-target="#hash2" type="button" role="tab"
                                                        aria-controls="hash" aria-selected="false">#</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTab2Content">
                                    <div class="tab-pane fade show active" id="percent2" role="tabpanel"
                                        aria-labelledby="percent-tab2">
                                        <div class="registrant cust_scroll">
                                            <div v-for="(rep, inx) in reported_improvement"
                                                class="d-flex justify-content-between registrant_section sml_flw"
                                                :key="inx">
                                                <div class="name">
                                                    <h3><span :class="'circle ' + rep.color"></span> {{ rep.name }}</h3>
                                                </div>
                                                <div class="percent">
                                                    <h3>{{ rep.percent }}%</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="hash2" role="tabpanel" aria-labelledby="hash-tab2">
                                        <div class="registrant cust_scroll">
                                            <div v-for="(rep, inx) in reported_improvement"
                                                class="d-flex justify-content-between registrant_section sml_flw"
                                                :key="inx">
                                                <div class="name">
                                                    <h3><span :class="'circle ' + rep.color"></span> {{ rep.name }}</h3>
                                                </div>
                                                <div class="percent">
                                                    <h3>{{ rep.count }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-12 text-center">
                                <div
                                    class="align-items-center d-flex d-xxl-flex justify-content-between w-100 white_card border_radius_20 h_170">
                                    <h2 class="no_data">No Data Found</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="self_report mt-0">
            <!-- Aggregate Responses -->
            <div class="row">
                <div class="col-md-12 col-xl-6 col-xxl-6 for-notepad mt-5 mt-lg-5" ref="AggregateResponsesDiv">
                    <div class="registrtans_site_main white_card border-radius-20 h-100">
                        <div class=" pad_bottom_non border-bottom registrtans_site activity_region">
                            <div class="row align-items-center">
                                <div class="col-8 col-sm-6">
                                    <h2 class="mb_20">Aggregate Responses</h2>
                                </div>
                                <div class="col-4 col-sm-6 text-end">
                                    <div class="d-flex justify-content-end align-items-baseline">
                                        <div v-if="hideAggregateResponseDownloadBtn"
                                            class="align-items-center d-flex justify-content-end">
                                            <div class="ml_24" data-html2canvas-ignore="true">
                                                <a v-if="SA_aggregate_show" href="javascript:" data-bs-toggle="modal"
                                                    data-bs-target="#download"
                                                    @click="prepareChart('AggregateResponsesDiv', 'SA-Aggregate-Responses', '', '', '', self_assessment_response)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16">
                                                        <g data-name="Group 12689">
                                                            <path data-name="Path 114"
                                                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                transform="translate(-3 -3)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </g>
                                                    </svg>
                                                </a>
                                                <a v-if="EE_aggregate_show" href="javascript:" data-bs-toggle="modal"
                                                    data-bs-target="#download"
                                                    @click="prepareChart('AggregateResponsesDiv', 'EE-Aggregate-Responses', '', '', '', ergo_eval_response)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16">
                                                        <g data-name="Group 12689">
                                                            <path data-name="Path 114"
                                                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                                transform="translate(-3 -3)"
                                                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="d-none dropdown calender_dropdown">
                                                <a href="javascript:void(0)" id="dropdownMenuLink4"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    class="ml_20 dropdown-toggle" role="button" data-bs-auto-close="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16">
                                                        <path data-name="Path 130"
                                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                            transform="translate(-2 -2)"
                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                    </svg>
                                                </a>
                                                <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                                    aria-labelledby="dropdownMenuLink4">
                                                    <div class="date pl_6">
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="heading_5 font_bold mb_10">From</div>
                                                            <a href="javascript:void(0)" class="reset_btn"
                                                                @click="resetDatesAR">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                                    x="0px" y="0px" viewBox="0 0 1000 1000"
                                                                    enable-background="new 0 0 1000 1000"
                                                                    xml:space="preserve">
                                                                    <metadata> Svg Vector Icons :
                                                                        http://www.onlinewebfonts.com/icon </metadata>
                                                                    <g>
                                                                        <path
                                                                            d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                        </div>


                                                        <div
                                                            class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                            <span class="mr_10">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" viewBox="0 0 16 16">
                                                                    <path data-name="Path 130"
                                                                        d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                        transform="translate(-2 -2)"
                                                                        style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                </svg>
                                                            </span>
                                                            <datepicker placeholder="MM DD, YYYY"
                                                                v-model="listConfig.aggregate_res_sdate"
                                                                :format="customFormatter" @selected="changeARStartDate"
                                                                :disabledDates="disabledFutureDates"></datepicker>
                                                            <p class="min-date-value d-none">
                                                                {{ listConfig.aggregate_res_sdate | formatDate }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="date pl_6 mt_20">
                                                        <div class="heading_5 font_bold mb_10">To</div>
                                                        <div
                                                            class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                                            <span class="mr_10">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" viewBox="0 0 16 16">
                                                                    <path data-name="Path 130"
                                                                        d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                                                        transform="translate(-2 -2)"
                                                                        style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                                </svg>
                                                            </span>
                                                            <datepicker placeholder="MM DD, YYYY"
                                                                v-model="listConfig.aggregate_res_edate"
                                                                :format="customFormatter" @selected="changeARStartDate"
                                                                :disabled="isDisabledAggregateRes"
                                                                :disabledDates="disabledDates"></datepicker>
                                                            <p class="max-date-value d-none">
                                                                {{ listConfig.aggregate_res_edate | formatDate }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="button mt_30">
                                                        <a href="javascript:" @click="updateAggregateResponseChartData"
                                                            class="btn btn_primary px_14">Update</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-2 p-lg-4 pick-graph" v-if="hideAggregateResponseDownloadBtn">
                            <div class="d-lg-flex align-items-center justify-content-between">
                                <div class="align-items-center d-flex justify-content-lg-between justify-content-center">
                                    <img :src="require('@/assets/images/resistant.svg')">
                                    <h3 class="total_h" v-if="SA_aggregate_show">Total Self-Assessment</h3>
                                    <h3 class="total_h" v-if="EE_aggregate_show">Total Ergo Eval</h3>
                                    <h4 class="for_count_sml">
                                        <span v-if="SA_aggregate_show"> {{ total_SA_responses }} </span>
                                        <span v-if="EE_aggregate_show"> {{ total_EE_responses }} </span>
                                        <img :src="require('@/assets/images/up_arrow.svg')" class="img-arrow">
                                    </h4>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show" :class="[this.listConfig.Default_sa_show ? 'active' : '']"
                                    id="SA-Aggregate" role="tabpanel" aria-labelledby="SA-Aggregate-tab2">
                                    <div class="line_chart">
                                        <line-chart ref="SAAggregateResponses" :height="180"
                                            :chart-data="SA_aggregate_datacollection"></line-chart>
                                    </div>
                                </div>
                                <div class="tab-pane fade show" :class="[this.listConfig.Default_sa_show ? '' : 'active']"
                                    id="EE-Aggregate" role="tabpanel" aria-labelledby="EE-Aggregate-tab2">
                                    <div class="line_chart">
                                        <line-chart ref="EEAggregateResponses" :height="180"
                                            :chart-data="EE_aggregate_datacollection"></line-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="bar_graph mb_34 mt_50 px-3">
                            <div class=" align-items-center d-md-flex d-xxl-flex justify-content-between w-100 h_270">
                                <h2 class="no_data">No Data Found</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Further Assistance Requested By Category -->
                <div class="col-xl-6 mt-5" ref="furtherAssistanceDiv">
                    <div class="white_card border_radius_20 h-100">
                        <div class="activity_region border-bottom p-3 pb_14 tab_report">
                            <div class="row">
                                <div class="col-8 col-sm-8 align-self-center py-1">
                                    <h2 class="mb_20">Further Assistance Needed</h2>
                                </div>
                                <div class="col-4 col-sm-4 text-end py-1">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="tab_report toggle_switch">
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="percent-tab3" data-bs-toggle="tab"
                                                        data-bs-target="#percent3" type="button" role="tab"
                                                        aria-controls="percent3" aria-selected="true">%</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="hash-tab3" data-bs-toggle="tab"
                                                        data-bs-target="#hash3" type="button" role="tab"
                                                        aria-controls="hash3" aria-selected="false">#</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="hideFurtherAssistanceDownloadBtn" class="ps-3"
                                            data-html2canvas-ignore="true">
                                            <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                                                @click="prepareChart('furtherAssistanceDiv', 'Further-assistance-needed', '', '', '', further_assistance_requested)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    viewBox="0 0 16 16">
                                                    <g data-name="Group 12689">
                                                        <path data-name="Path 114"
                                                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                            transform="translate(-3 -3)"
                                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="myTab3Content">
                            <div class="tab-pane fade show active" id="percent3" role="tabpanel"
                                aria-labelledby="percent-tab3">
                                <div class="total_self">
                                    <div class="row">
                                        <div :class="'col-12 col-md-6 col-lg-6 ' + dbe.class"
                                            v-for="(dbe, inx) in further_assistance_requested" :key="inx">
                                            <div class="category_box">
                                                <img :src="require('@/assets/images/laptop.png')" alt="" width="28"
                                                    height="28">
                                                <div class="align-items-start d-flex with_followup">
                                                    <div class="detailsProgress justify-content-between w-100">
                                                        <div
                                                            class="align-items-center d-flex justify-content-between w-100 chid-height">
                                                            <h2 class="min_hgt">{{ dbe.name }}</h2>
                                                            <h3 class="min_hgt">{{ dbe.percent }}%</h3>
                                                        </div>
                                                        <div class="progressBar">
                                                            <ul class="d-flex">
                                                                <li v-for="(level, indx) in dbe.levels"
                                                                    :class="level == '1' ? 'bg_green' : ''" :key="indx">
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show" id="hash3" role="tabpanel" aria-labelledby="hash-tab3">
                                <div class="total_self">
                                    <div class="row">
                                        <div :class="'col-12 col-md-6 col-lg-6 border-one'"
                                            v-for="(dbe, inx) in further_assistance_requested" :key="inx">
                                            <div class="category_box">
                                                <img :src="require('@/assets/images/laptop.png')" alt="" width="28"
                                                    height="28">
                                                <div class="align-items-start d-flex with_followup">
                                                    <div class="detailsProgress justify-content-between w-100">
                                                        <div
                                                            class="align-items-center d-flex justify-content-between w-100 chid-height">
                                                            <h2 class="min_hgt">{{ dbe.name }}</h2>
                                                            <h3 class="min_hgt">{{ dbe.value }}</h3>
                                                        </div>
                                                        <div class="progressBar">
                                                            <ul class="d-flex">
                                                                <li v-for="(level, indx) in dbe.levels"
                                                                    :class="level == '1' ? 'bg_green' : ''" :key="indx">
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!further_assistance_requested.length" class="bar_graph mb_34 mt_50 px-3">
                                <div class=" align-items-center d-md-flex d-xxl-flex justify-content-between w-100 h_270">
                                    <h2 class="no_data">No Data Found</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- table -->
        <div class="table_admin mt_50">
            <div class="col-md-12">
                <div class="table_card">
                    <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-6 col-xxl-6">
                            <div class="align-left ">
                                <h3 class="card_heading employee_table_top text-center text-sm-start">Employee Feedback</h3>
                            </div>
                        </div>
                        <div class="col-md-12 col-xl-6 col-xxl-6">
                            <div class="table_heading mb-0 emp-dash">
                                <div class="table_search ergo_eval_table">
                                    <div class="d-flex align-items-center">
                                        <a href="javascript:" class="ml_12 xl_download" v-if="response_data.length"
                                            @click="exportAllData()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16">
                                                <g data-name="Group 12689">
                                                    <path data-name="Path 114"
                                                        d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                                        transform="translate(-3 -3)" style="
                                                    fill: none;
                                                    stroke: #1a191c;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                  " />
                                                </g>
                                            </svg>
                                            Download All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="datatable_wrapper" class="dataTables_wrapper no-footer feed_ul">
                        <div class="dataTables_length float-xl-start float-none mb-3 mb-xl-0 text-center text-lg-start"
                            id="datatable_length">
                            <label>Reports
                                <select name="datatable_length" aria-controls="datatable" class=""
                                    v-model.number="itemsPerPage" @change="getResponseBysearch">
                                    <option>10</option>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>500</option>
                                </select>
                                entries</label>
                        </div>
                        <div class="align-items-start d-lg-flex justify-content-end mb-4 mb-xl-0">
                            <div id="datatable_filter" class="dataTables_filter i_filter ms-lg-4 float-none text-center">
                                <h1 v-if="reportCheckbox.length && followup_dropdown_status" class="fup-updatestatus mb-50 text-center me-0 me-lg-3">
                                    Update Status
                                </h1>
                                <label v-else>
                                    <input type="search" class="" placeholder="Search" aria-controls="datatable"
                                        v-model="listConfig.search_string" v-on:keyup.enter="getResponseBysearch" />
                                    <a v-if="listConfig.search_string" href="javascript:void(0)"
                                        @click="getResponseBysearch" class="search_icon slty_up">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00"
                                            class="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </a>
                                    <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                                        listConfig.search_string = null;
                                    getResponseBysearch();
                                    " class="crossicon">
                                        X
                                    </a>
                                </label>
                            </div>
                            <div class="ml_24 marg_auto ms-0 ms-md-3 " v-if="followup_dropdown_status">
                                <div class="form-group region_select status_box status_select fwl_sts  sts_black mb-0">
                                    <v-select class="vs-drop-cust" @input="updateBulkStatus($event)"
                                        :reduce="(update_status_list) => update_status_list.id"
                                        :options="update_status_list" label="name" placeholder="All Status" name="Status"
                                        v-model="listConfig.search_status" v-validate="'required'"
                                        v-if="reportCheckbox.length" />
                                    <v-select class="vs-drop-cust" @input="updateBulkStatus($event)"
                                        :reduce="(status_list) => status_list.id" :options="status_list" label="name"
                                        placeholder="All Status" name="Status" v-model="listConfig.search_status"
                                        v-validate="'required'" v-else />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive table_audit_log feedback_table report_table">
                        <table class="table table-bordered table dataTable no-footer">
                            <thead>
                                <tr>
                                    <th class="sort sml_check" style="min-width: 10px" v-if="followup_dropdown_status">
                                        <div class=" form_check_box d-flex align-items-center mt-1 ">
                                            <input type="checkbox" v-model="selectAll" aria-label="..."
                                                id="flexCheckDefault" class="form-check-input ms-0"
                                                @change="checkSelected($event)" />
                                            <label for="flexCheckDefault"></label>
                                        </div>
                                    </th>
                                    <th class="sort feed_name">
                                        <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'first_name'"
                                            @sorting="sorting($event)" />
                                    </th>
                                    <th class="sort feed_name">
                                        <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'last_name'"
                                            @sorting="sorting($event)" />
                                    </th>
                                    <th class="sort feed_name">
                                        <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'user_email'"
                                            @sorting="sorting($event)" />
                                    </th>
                                    <th class="sort feed_name sml_region">
                                        <SortIcons :lable="'Region'" :listConfig="listConfig" :sortBy="'region'"
                                            @sorting="sorting($event)" />
                                    </th>
                                    <th class="sort feed_name emp_flw_col">
                                        <SortIcons :lable="'Date of Follow-Up'" :listConfig="listConfig"
                                            :sortBy="'created_at'" @sorting="sorting($event)" />
                                    </th>

                                    <th scope="col">Note </th>
                                    <th scope="col" class="feed_col">
                                        <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                                            @sorting="sorting($event)" />
                                    </th>
                                    <th scope="col" class="feed_col" style="text-align: center;">ACTION </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(response, indx) in response_data" :key="indx">
                                    <td style="min-width: 10px" v-if="followup_dropdown_status">
                                        <div class="form_check_box d-flex align-items-center">
                                            <input type="checkbox" v-model="reportCheckbox" aria-label="..."
                                                :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                                                @change="checkSelected($event)" :value="response.id" />
                                            <label :for="'flexCheckDefault' + indx" class=""></label>
                                        </div>
                                    </td>
                                    <td>
                                        <p>
                                            {{ response.first_name | capitalize }}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{{ response.last_name | capitalize }}</p>
                                    </td>
                                    <td>
                                        <p class="email_tag">
                                            <a :href="'mailto:' + response.user_email">{{ response.user_email }}</a>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="email_tag">
                                            {{ response.region ? response.region : "NA" }}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{{ response.formated_date }}</p>
                                    </td>
                                    <td style="width: 16%;">
                                        <p style="max-width: 485px; width: 100%;word-break: break-word;white-space: normal;"
                                            v-if="response.reported_improvement_feedback && response.reported_improvement_feedback.length > 60">
                                            {{ (response.reported_improvement_feedback) ?
                                                response.reported_improvement_feedback.substring(0, 60) + "..." : 'Not Answered'
                                            }}
                                        </p>
                                        <p style="max-width: 485px; width: 100%;word-break: break-word;white-space: normal;"
                                            v-else>
                                            {{ (response.reported_improvement_feedback) ?
                                                response.reported_improvement_feedback : 'Not Answered' }}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{{ response.status }}</p>
                                    </td>
                                    <td align="center">
                                        <div class="edit_btn">
                                            <p>
                                                <a href="javascript:"
                                                    @click="showReportDataSet(response.dataset, response.first_name + ' ' + response.last_name)"
                                                    class="view-dshboad-btn" title="View" data-bs-toggle="modal"
                                                    data-bs-target="#dataset">
                                                    <img class="view-icon"
                                                        :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                                        alt="img" />
                                                </a>
                                                <!-- <a href="javascript:" @click="getSingleFollowupDetails(response.id)" class="view-dshboad-btn" title="View"  data-bs-toggle="modal" data-bs-target="#followupDetails">
                                                    <img class="view-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')" alt="img" />
                                                </a> -->
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="data_length == 0">
                                    <td colspan="5" class="text-center">No Records found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                    <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                        :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
        <div class="modal box_up fade download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="downloadLabel">
                            <h3 class="heading_2">Download</h3>
                        </h5>
                        <button type="button" class="btn-close downloadBtn" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-around">
                            <div class="col-md-3">
                                <div class="image_upload text-center downloadDocumentClass disabled">
                                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('document')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42"
                                            class="d-block">
                                            <path data-name="Path 122"
                                                d="M13.889 23h13.333m-13.333 8.889h13.333M31.667 43H9.444A4.444 4.444 0 0 1 5 38.556V7.444A4.444 4.444 0 0 1 9.444 3h12.414a2.222 2.222 0 0 1 1.571.651L35.46 15.682a2.222 2.222 0 0 1 .651 1.571v21.3A4.444 4.444 0 0 1 31.667 43z"
                                                transform="translate(-4 -2)"
                                                style=" fill:none; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px " />
                                        </svg>
                                        Download as a document
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="image_upload text-center downloadImageClass">
                                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('image')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063"
                                            viewBox="0 0 38.891 38.063" class="d-block">
                                            <path data-name="Path 143"
                                                d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                                                transform="translate(-2.586 -3)"
                                                style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                                        </svg>
                                        Download as an image
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top-0 mx-auto mb_50">
                        <button type="button" class="btn btn_primary_light mr_30" data-bs-dismiss="modal"> Close </button>
                        <button type="button" class="btn btn_primary sml_download"
                            @click="downloadChart()">Download</button>
                    </div>
                </div>
            </div>
        </div>
        <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard" v-bind:dataSet="dataSet"
            v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template> 
<script>
import $ from "jquery";
import moment from "moment";
import "vue-select/dist/vue-select.css";
import Datepicker from "vuejs-datepicker";
import PieChart from "../../components/charts/PieChart.js";
import BarChart from "../../components/charts/BarChart.js";
import LineChart from "../../components/charts/LineChart.js";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction from '../../mixin/commonFunction';
import DatasetModal from "@/components/DatasetModal";
// import jsPDF from 'jspdf';
import html2Canvas from 'html2canvas';
import { utils, writeFileXLSX } from 'xlsx';
import ConfirmDialogue from "@/components/ConfirmDialogue";
import exportFromJSON from "export-from-json";
import commonService from "../../services/commonService";

export default {
    name: "my_reports",
    mixins: [commonFunction],
    components: {
        Datepicker,
        PieChart,
        BarChart,
        LineChart,
        VPagination,
        SortIcons,
        DatasetModal,
        ConfirmDialogue
    },
    data() {
        return {
            downloadMessage: 'Dataset',
            dashboard: 'Follow up Feedback',
            dataSet: [],
            isEmptyDataSet: true,
            show: false,
            SA_aggregate_show: true,
            total_SA_responses: 0,
            total_EE_responses: 0,
            EE_aggregate_show: false,
            isDisabledSAHelpful: "",
            isDisabledEEHelpful: "",
            isDisabledImprovement: "",
            isDisabledAggregateRes: "",
            chart_options: {
                legend: { display: false },
                cornerRadius: 7,
            },
            reportedDataCollection: {
                labels: [],
                // labels: ['Same', 'Better', 'Worse'],
                datasets: [{
                    backgroundColor: ['#6665DD', '#FF6A81', '#F2A149'],
                    data: []
                    // data: [0, 0, 0]
                }]
            },
            SAHelpfuldatacollection: {
                labels: [],
                // labels: ["Yes","No"],
                datasets: [{
                    data: [],
                    label: "Was the Self- Assessment helpful?",
                    backgroundColor: ['#4880FF', '#29CB96'],
                    maxBarThickness: 40,
                }],
            },
            EEHelpfuldatacollection: {
                labels: [],
                datasets: [{
                    data: [],
                    label: "Was the 1:1 Eval helpful?",
                    backgroundColor: ['#4880FF', '#29CB96'],
                    maxBarThickness: 40,
                }],
            },
            EE_aggregate_datacollection: {
                labels: [
                    "JUN",
                    "JULY",
                    "AUG",
                    "SEP",
                    "OCT",
                    "NOV",
                    "DEC",
                ],
                datasets: [
                    {
                        data: [],
                        label: "Aggregate Responses",
                        borderColor: "#29CB96",
                        fill: false,
                    },
                ],
            },
            SA_aggregate_datacollection: {
                labels: [
                    "JUN",
                    "JULY",
                    "AUG",
                    "SEP",
                    "OCT",
                    "NOV",
                    "DEC",
                ],
                datasets: [
                    {
                        data: [],
                        label: "Aggregate Responses",
                        borderColor: "#4880FF",
                        fill: false,
                    },
                ],
            },
            selected: '',
            isDisabled: '',
            region_list: [{
                id: 'ALL',
                name: 'ALL'
            }],
            // status_list: [
            //     {
            //         id: null,
            //         name: "All Status",
            //     }, {
            //         id: "Pending",
            //         name: "Pending",
            //     }, {
            //         id: "Scheduled",
            //         name: "Scheduled",
            //     }, {
            //         id: "No Reply",
            //         name: "No Reply",
            //     }, {
            //         id: "Declined",
            //         name: "Declined",
            //     }, {
            //         id: "Hidden",
            //         name: "Hidden",
            //     }, {
            //         id: "Complete",
            //         name: "Complete",
            //     },
            // ],
            status_list: commonService.followupsReportStatus(),
            update_status_list: commonService.followupsUpdateStatusWithHidden(),
            disabledDates: {
                to: new Date(Date.now() - 8640000),
            },
            disabledFutureDates: {
                from: new Date(Date.now()),
            },
            currentPage: 1,
            itemsPerPage: 10,
            data_length: 0,
            response_data: [],
            listConfig: {
                //Feedback table filters
                offset: (this.currentPage - 1) * this.itemsPerPage,
                limit: this.itemsPerPage,
                sortBy: "created_at",
                order: "DESC",
                search_string: null,
                search_status: null,
                feedback_sdate: null,
                feedback_edate: null,
                feedback_regionBy: null,

                //Main filter...
                start_date: null,
                end_date: null,
                regionBy: null,
                //Self-Assessment Helpful filter...
                SAHelpful_sdate: null,
                SAHelpful_edate: null,
                SAHelpful_regionBy: null,
                //Ergo eval Helpful filter...
                EEHelpful_sdate: null,
                EEHelpful_edate: null,
                EEHelpful_regionBy: null,
                //Reported Improvement filter...
                improvement_sdate: null,
                improvement_edate: null,
                improvement_regionBy: null,
                //Aggregate Response filter...
                aggregate_res_sdate: null,
                aggregate_res_edate: null,
                aggregate_res_regionBy: null,
                Default_sa_show: true,
            },
            reported_improvement_BetterCSV: [],
            reported_improvement_SameCSV: [],
            reported_improvement_WorseCSV: [],
            hideReportImprovementDownloadBtn: true,
            reported_improvement: [],
            hideEEHelpfulDownloadBtn: true,
            ergo_eval_helpful: [],
            hideSAHelpfulDownloadBtn: true,
            self_assessment_helpful: [],
            self_assessment_response: [],
            self_assessment_responseCSV: [],
            ergo_eval_response: [],
            hideAggregateResponseDownloadBtn: true,
            ergo_eval_responseCSV: [],
            further_assistance_requested: [],
            followup: [],
            self_assessment_helpful_YesCSV: [],
            self_assessment_helpful_NoCSV: [],
            ergo_eval_helpful_YesCSV: [],
            ergo_eval_helpful_NoCSV: [],
            downloadRef: '',
            downloadType: 'image',
            downloadImageName: '',
            downloadMaxHeight: '',
            downloadMinHeight: '',
            downloadHeightClass: '',
            downloadCSVData: '',
            rowsData: [],
            further_assistance_csv: [],
            hideFurtherAssistanceDownloadBtn: true,
            previousStartDate: null,
            followup_dropdown_status: false,
            oldSelectedCheckbox: null,
            reportCheckbox: [],
            reportCheckboxArr: {
                status: null,
                checkboxes: [],
            },
        };
    },
    destroyed() {
        this.$parent.employeeDashboard = false
        // this.$router.go()
    },
    mounted() {
        document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
        this.$parent.employeeDashboard = true;
        //Get all regions from Client data...
        var clientData = this.$store.getters["auth/authUser"];
        // For Displaying Status dropdown
        this.followup_dropdown_status = clientData.client.followup_status;
        var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
        var clientRegionsArr = clientRegions.split(",");
        for (let i = 0; i < clientRegionsArr.length; i++) {
            this.region_list.push({ "id": clientRegionsArr[i], "name": clientRegionsArr[i] });
        }
        this.getSAHelpfulChartData();
        this.getEEHelpfulChartData();
        this.getReportedImprovementChartData();
        this.getAggregateResponseChartData();

        this.listConfig.offset = (this.currentPage - 1) * this.itemsPerPage;
        this.listConfig.limit = this.itemsPerPage;
        this.getAllResponses();
        this.getFurtherAssistanceData();
    },
    computed: {
        selectAll: {
            get: function () {
                return this.response_data
                    ? this.reportCheckbox.length == this.response_data.length
                    : false;
            },
            set: function (value) {
                var reportCheckbox = [];
                if (value) {
                    this.response_data.forEach(function (data) {
                        reportCheckbox.push(data.id);
                    });
                }
                this.reportCheckbox = reportCheckbox;
            },
        },
        btnText: function () {
            if (this.show) {
                return "";
            }
            return "";
        },
        positionText: function () {
            var endIndex = this.currentPage * this.itemsPerPage,
                startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
            if (this.data_length == 0) {
                startIndex = 0;
            }
            return (
                "Showing " +
                startIndex +
                " to " +
                (endIndex > this.data_length ? this.data_length : endIndex) +
                " of " +
                this.data_length
            );
        },
        pages() {
            if (this.data_length <= this.itemsPerPage) {
                return [1];
            }
            let pages_array = [
                ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
            ].map((e) => e + 1);
            return pages_array;
        },
    },
    watch: {
        currentPage(newValue) {
            this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
            this.getAllResponses();
        },
    },
    methods: {
        checkSelected(event) {
            if (event.target.checked) {
                this.listConfig.search_status = "";
            } else {
                if (this.reportCheckbox.length == 0) {
                    this.listConfig.search_status = this.oldSelectedCheckbox;
                }
            }
        },
        showReportDataSet(dataSet, userName) {
            if (localStorage.getItem("userToken")) {
                var _this = this;
                _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
                if (dataSet != null) {
                    var i = 1;
                    for (const item in dataSet) {
                        dataSet[item].id = i++;
                        if (dataSet[item].options) {
                            dataSet[item].answer = ''; //reset 
                            for (const item1 in dataSet[item].options) {
                                if (dataSet[item].options[item1].answer) {
                                    if (dataSet[item].answer) {
                                        dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                                    } else {
                                        dataSet[item].answer = dataSet[item].options[item1].answer;
                                    }

                                }
                            }
                        }
                        if (dataSet[item].subquestions) {
                            dataSet[item].answer = '';
                            for (const item2 in dataSet[item].subquestions) {
                                if (dataSet[item].subquestions[item2].answer) {
                                    if (dataSet[item].answer) {
                                        dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                                    }
                                    else {
                                        dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                                    }
                                }
                            }
                        }
                    }
                    this.dataSet = dataSet;
                    _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
                    this.isEmptyDataSet = Object.keys(dataSet).length === 0;
                    this.downloadMessage = "Dataset of Follow up for " + userName;
                } else {
                    this.dataSet = [];
                    this.isEmptyDataSet = true;
                    _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
                }
            } else {
                _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
            }
        },
        prepareChart(ref, imageName, maxHeight, minHeight, heightClass, csvData) {
            this.downloadRef = ref;
            this.downloadImageName = imageName;
            this.downloadMaxHeight = maxHeight;
            this.downloadMinHeight = minHeight;
            this.downloadHeightClass = heightClass;
            this.downloadCSVData = csvData;
            this.enableDownloadBtn('image');
        },
        enableDownloadBtn(btnType) {
            if (btnType == 'image') {
                $('.downloadImageClass').removeClass('disabled');
                $('.downloadDocumentClass').addClass('disabled');
            }
            else if (btnType == 'document') {
                $('.downloadDocumentClass').removeClass('disabled');
                $('.downloadImageClass').addClass('disabled');
            }
            this.downloadType = btnType;
        },
        async downloadChart() {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            //Audit log entry  
            var sectionName = '';
            switch (_this.downloadRef) {
                case 'SelfAssessmentHelpfulDiv':
                    sectionName = 'The Self-Assessment helpful';
                    break;
                case 'EvalHelpfulDiv':
                    sectionName = 'The 1:1 Eval helpful';
                    break;
                case 'ReportedImprovementDiv':
                    sectionName = 'Reported Improvement';
                    break;
                case 'AggregateResponsesDiv':
                    sectionName = 'Aggregate Responses';
                    break;
                case 'furtherAssistanceDiv':
                    sectionName = 'Further Assistance Needed';
                    break;
            }
            var requestData = { 'section': sectionName, 'dashboard': 'Follow-Up Feedback ' };
            let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
                data: this.encodeAPIRequestWithImages(requestData),
            };
            this.axios(config)
                .then(({ data }) => {
                    console.log(data.message);
                });
            if (_this.downloadHeightClass != '') {
                $('.cust_scroll').css({ 'overflow': 'unset' });
                $('.' + _this.downloadHeightClass).css({ 'height': _this.downloadMaxHeight });
            }
            $('.white_card').css({ 'box-shadow': 'unset' });
            const el = _this.$refs[_this.downloadRef];
            const options = {
                type: "dataURL",
                scrollX: 0,
                scrollY: -window.scrollY
            };
            const printCanvas = await html2Canvas(el, options);
            setTimeout(() => {
                const link = document.createElement("a");
                if (_this.downloadType == 'image') {
                    link.setAttribute("download", _this.downloadImageName + '.png');
                    link.setAttribute(
                        "href",
                        printCanvas
                            .toDataURL("image/png")
                            .replace("image/png", "image/octet-stream")
                    );
                    link.click();
                }
                else if (_this.downloadType == 'document') {
                    var xlsxSheetData1 = ''; var xlsxSheetName1 = '';
                    var xlsxSheetData2 = ''; var xlsxSheetName2 = '';
                    var xlsxSheetData3 = ''; var xlsxSheetName3 = '';
                    var xlsxSheetData4 = ''; var xlsxSheetName4 = '';
                    if (_this.downloadRef == 'SelfAssessmentHelpfulDiv') {
                        if (_this.self_assessment_helpful_YesCSV.length) {
                            xlsxSheetName1 = 'Yes Helpful';
                            xlsxSheetData1 = utils.json_to_sheet(_this.self_assessment_helpful_YesCSV);
                        }
                        if (_this.self_assessment_helpful_NoCSV.length) {
                            xlsxSheetName2 = 'No Helpful';
                            xlsxSheetData2 = utils.json_to_sheet(_this.self_assessment_helpful_NoCSV);
                        }
                    }
                    else if (_this.downloadRef == 'EvalHelpfulDiv') {
                        if (_this.ergo_eval_helpful_YesCSV.length) {
                            xlsxSheetName1 = 'Yes Helpful';
                            xlsxSheetData1 = utils.json_to_sheet(_this.ergo_eval_helpful_YesCSV);
                        }
                        if (_this.ergo_eval_helpful_NoCSV.length) {
                            xlsxSheetName2 = 'No Helpful';
                            xlsxSheetData2 = utils.json_to_sheet(_this.ergo_eval_helpful_NoCSV);
                        }
                    }
                    else if (_this.downloadRef == 'ReportedImprovementDiv') {
                        if (_this.reported_improvement_BetterCSV.length) {
                            xlsxSheetName1 = 'Better Reported Improvement';
                            xlsxSheetData1 = utils.json_to_sheet(_this.reported_improvement_BetterCSV);
                        }
                        if (_this.reported_improvement_SameCSV.length) {
                            xlsxSheetName2 = 'Same Reported Improvement';
                            xlsxSheetData2 = utils.json_to_sheet(_this.reported_improvement_SameCSV);
                        }
                        if (_this.reported_improvement_WorseCSV.length) {
                            xlsxSheetName3 = 'Worse Reported Improvement';
                            xlsxSheetData3 = utils.json_to_sheet(_this.reported_improvement_WorseCSV);
                        }
                    }
                    else if (_this.downloadRef == 'AggregateResponsesDiv') {
                        if (_this.self_assessment_responseCSV.length) {
                            xlsxSheetName1 = 'Total Self-Assessment';
                            xlsxSheetData1 = utils.json_to_sheet(_this.self_assessment_responseCSV);
                        }
                        if (_this.ergo_eval_responseCSV.length) {
                            xlsxSheetName2 = 'Total Ergo Eval';
                            xlsxSheetData2 = utils.json_to_sheet(_this.ergo_eval_responseCSV);
                        }
                    }
                    else if (_this.downloadRef == 'furtherAssistanceDiv') {
                        if (_this.further_assistance_csv.length) {
                            xlsxSheetName1 = 'Further Assistance Requested';
                            xlsxSheetData1 = utils.json_to_sheet(_this.further_assistance_csv);
                        }
                    }
                    // A workbook is the name given to an Excel file
                    var wb = utils.book_new(); // make Workbook of Excel
                    // add Worksheet to Workbook // Workbook contains one or more worksheets
                    if (xlsxSheetData1) {
                        utils.book_append_sheet(wb, xlsxSheetData1, xlsxSheetName1);
                    }
                    if (xlsxSheetData2) {
                        utils.book_append_sheet(wb, xlsxSheetData2, xlsxSheetName2);
                    }
                    if (xlsxSheetData3) {
                        utils.book_append_sheet(wb, xlsxSheetData3, xlsxSheetName3);
                    }
                    if (xlsxSheetData4) {
                        utils.book_append_sheet(wb, xlsxSheetData4, xlsxSheetName4);
                    }
                    // export Excel file
                    if (wb.SheetNames.length) {
                        writeFileXLSX(wb, _this.downloadImageName + '.xlsx') // name of the file is 'book.xlsx'
                    }
                    ///////////// Commented PDF Code //////////////
                    // //Adjust width and height...
                    // var topMargin = 20;
                    // var leftMargin = 10;
                    // var imgWidth =  (printCanvas.width * 60) / 240;
                    // var imgHeight = (printCanvas.height * 60) / 240;
                    // if(imgWidth < 100){
                    // leftMargin = 50;
                    // }
                    // else if(imgWidth < 130 && imgWidth > 100){
                    // leftMargin = 30;
                    // }
                    // else if(imgWidth < 160 && imgWidth > 130){
                    // leftMargin = 20;
                    // }
                    // //jsPDF changes...
                    // var pdf = new jsPDF('p', 'mm', 'a4');
                    // pdf.addImage(printCanvas, 'png', leftMargin, topMargin, imgWidth, imgHeight); // 2: 19
                    // pdf.save(_this.downloadImageName+`${$('.pdf-title').text()}.pdf`);
                }
                if (_this.downloadHeightClass != '') {
                    $('.cust_scroll').css({ 'overflow': 'auto' });
                    $('.' + _this.downloadHeightClass).css({ 'height': _this.downloadMinHeight });
                }
                $('.white_card').css({ 'box-shadow': '5px 10px 20px #f198361a' });
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                document.getElementsByClassName("downloadBtn")[0].click();
            }, 100);
        },
        exportToCsv(filename, rows) {
            var processRow = function (row) {
                var finalVal = '';
                for (var j = 0; j < row.length; j++) {
                    var innerValue = row[j] === null ? '' : row[j].toString();
                    if (row[j] instanceof Date) {
                        innerValue = row[j].toLocaleString();
                    }
                    var result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0)
                        result = '"' + result + '"';
                    if (j > 0)
                        finalVal += ',';
                    finalVal += result;
                }
                return finalVal + '\n';
            };
            var csvFile = '';
            for (var i = 0; i < rows.length; i++) {
                csvFile += processRow(rows[i]);
            }
            var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            }
            else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        updateAggregateResponseChartData() {
            document.getElementById("dropdownMenuLink4").click();
            this.getAggregateResponseChartData();
        },
        updateReportedImprovementChartData() {
            document.getElementById("dropdownMenuLink3").click();
            this.getReportedImprovementChartData();
        },
        updateEEHelpfulChartData() {
            document.getElementById("dropdownMenuLink2").click();
            this.getEEHelpfulChartData();
        },
        updateSAHelpfulChartData() {
            document.getElementById("dropdownMenuLink1").click();
            this.getSAHelpfulChartData();
        },
        onPageChange(page) {
            console.log(page);
            this.currentPage = page;
        },
        sorting($event) {
            this.listConfig.sortBy == $event.sortBy;
            this.listConfig.order == $event.order;
            this.getAllResponses();
        },
        showSATotal() {
            this.SA_aggregate_show = true;
            this.EE_aggregate_show = false;
            this.listConfig.Default_sa_show = true;
            this.getReportedImprovementChartData();
            this.getAggregateResponseChartData();
            this.getResponseBysearch();
            this.getFurtherAssistanceData();
        },
        showEETotal() {
            this.EE_aggregate_show = true;
            this.SA_aggregate_show = false;
            this.listConfig.Default_sa_show = false;
            this.getReportedImprovementChartData();
            this.getAggregateResponseChartData();
            this.getResponseBysearch();
            this.getFurtherAssistanceData();
        },
        resetDatesMain() {
            // if (
            //     (this.listConfig.end_date != "" && this.listConfig.end_date != null) ||
            //     this.listConfig.regionBy != ""
            // ) {
            //Main Filter
            this.listConfig.start_date = "";
            this.listConfig.end_date = "";
            this.listConfig.regionBy = "";
            this.isDisabled = true;
            this.Default_sa_show = true;
            //Self-Assessment Helpful Filter
            this.listConfig.SAHelpful_sdate = "";
            this.listConfig.SAHelpful_edate = "";
            this.listConfig.SAHelpful_regionBy = "";
            this.getSAHelpfulChartData();
            //Ergo Eval Helpful Filter
            this.listConfig.EEHelpful_sdate = "";
            this.listConfig.EEHelpful_edate = "";
            this.listConfig.EEHelpful_regionBy = "";
            this.getEEHelpfulChartData();
            //Reported Improvement Helpful Filter
            this.listConfig.improvement_sdate = "";
            this.listConfig.improvement_edate = "";
            this.listConfig.improvement_regionBy = "";
            this.getReportedImprovementChartData();
            //Aggregate Responses Filter
            this.listConfig.aggregate_res_sdate = "";
            this.listConfig.aggregate_res_edate = ""
            this.listConfig.aggregate_res_regionBy = "";
            this.getAggregateResponseChartData();
            //Followup Feedback Table Filter
            this.listConfig.feedback_sdate = "";
            this.listConfig.feedback_edate = "";
            this.listConfig.feedback_regionBy = "";
            this.getResponseBysearch();
            this.getFurtherAssistanceData();
            // }
        },
        resetDatesSA() {
            this.listConfig.SAHelpful_sdate = "";
            this.listConfig.SAHelpful_edate = "";
            this.updateSAHelpfulChartData();
        },
        resetDatesEE() {
            this.listConfig.EEHelpful_sdate = "";
            this.listConfig.EEHelpful_edate = "";
            this.updateEEHelpfulChartData();
        },
        resetDatesRI() {
            this.listConfig.improvement_sdate = "";
            this.listConfig.improvement_edate = "";
            this.updateReportedImprovementChartData();
        },
        resetDatesAR() {
            this.listConfig.aggregate_res_sdate = "";
            this.listConfig.aggregate_res_edate = "";
            this.updateAggregateResponseChartData();
        },
        getSAHelpfulChartData() {
            var _this = this;
            if (localStorage.getItem("userToken")) {
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/sa-helpful-data",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.records.data);
                        _this.self_assessment_helpful = false;
                        _this.SAHelpfuldatacollection.labels = [];
                        _this.SAHelpfuldatacollection.datasets[0].data = [];
                        if (data.success) {
                            _this.self_assessment_helpful_YesCSV = decodedJson.self_assessment_helpful_YesCSV;
                            _this.self_assessment_helpful_NoCSV = decodedJson.self_assessment_helpful_NoCSV;
                            _this.self_assessment_helpful = decodedJson.sAHelpfulDataCollection;
                            if (decodedJson.sAHelpfulDataCollection) {
                                _this.self_assessment_helpful.forEach(function (item) {
                                    // _this.SAHelpfuldatacollection.labels.push(item.name + " (" + item.percent + "%)");
                                    _this.SAHelpfuldatacollection.labels.push(item.name);
                                    _this.SAHelpfuldatacollection.datasets[0].data.push(item.count);
                                });
                                _this.SAHelpfuldatacollection.datasets[0].data.push(0);
                            }
                            setTimeout(() => {

                                _this.$refs.SA_helpful.renderChart(
                                    _this.SAHelpfuldatacollection,
                                    _this.chart_options
                                );
                            }, 100);
                        }
                        if (_this.self_assessment_helpful.length) {
                            _this.hideSAHelpfulDownloadBtn = true;
                        }
                        else {
                            _this.hideSAHelpfulDownloadBtn = false;
                        }
                    })
            }
            else {
                _this.tokenExpired();
            }
        },
        getEEHelpfulChartData() {
            var _this = this;
            if (localStorage.getItem("userToken")) {
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/ee-helpful-data",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: this.encodeAPIRequest(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.records.data);
                        _this.ergo_eval_helpful = false;
                        _this.EEHelpfuldatacollection.labels = [];
                        _this.EEHelpfuldatacollection.datasets[0].data = [];
                        if (data.success) {
                            _this.ergo_eval_helpful_YesCSV = decodedJson.ergo_eval_helpful_YesCSV;
                            _this.ergo_eval_helpful_NoCSV = decodedJson.ergo_eval_helpful_NoCSV;
                            _this.ergo_eval_helpful = decodedJson.eeHelpfulDataCollection;
                            if (decodedJson.eeHelpfulDataCollection) {
                                _this.ergo_eval_helpful.forEach(function (item) {
                                    // _this.EEHelpfuldatacollection.labels.push(item.name + " (" + item.percent + "%)");
                                    _this.EEHelpfuldatacollection.labels.push(item.name);
                                    _this.EEHelpfuldatacollection.datasets[0].data.push(item.count);
                                });
                                _this.EEHelpfuldatacollection.datasets[0].data.push(0);
                            }
                            setTimeout(() => {
                                _this.$refs.EE_helpful.renderChart(_this.EEHelpfuldatacollection, _this.chart_options);
                            }, 100);
                        }
                        if (_this.ergo_eval_helpful.length) {
                            _this.hideEEHelpfulDownloadBtn = true;
                        }
                        else {
                            _this.hideEEHelpfulDownloadBtn = false;
                        }
                    })
            }
            else {
                _this.tokenExpired();
            }
        },
        getReportedImprovementChartData() {
            var _this = this;
            if (localStorage.getItem("userToken")) {
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/reported-improvement-data",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.records.data);
                        _this.reported_improvement = false;
                        _this.reportedDataCollection.labels = [];
                        _this.reportedDataCollection.datasets[0].data = [];
                        if (data.success) {
                            _this.reported_improvement_BetterCSV = decodedJson.reported_improvement_BetterCSV;
                            _this.reported_improvement_SameCSV = decodedJson.reported_improvement_SameCSV;
                            _this.reported_improvement_WorseCSV = decodedJson.reported_improvement_WorseCSV;
                            _this.reported_improvement = decodedJson.reported_improvement;
                            if (decodedJson.reported_improvement) {
                                _this.reported_improvement.forEach(function (item) {
                                    _this.reportedDataCollection.labels.push(item.name + " (" + item.percent + "%)");
                                    _this.reportedDataCollection.datasets[0].data.push(item.count);
                                });
                            }
                            setTimeout(() => {
                                _this.$refs.reportedImprovementPieChart.renderChart(_this.reportedDataCollection, _this.chart_options);
                            }, 100);
                        }
                        if (_this.reported_improvement.length) {
                            _this.hideReportImprovementDownloadBtn = true;
                        }
                        else {
                            _this.hideReportImprovementDownloadBtn = false;
                        }
                    })
            }
            else {
                _this.tokenExpired();
            }
        },
        getAggregateResponseChartData() {
            var _this = this;
            if (localStorage.getItem("userToken")) {
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/aggregate-response-data",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.data);
                        var checkAggregateCounts = 0;
                        _this.self_assessment_response = false;
                        _this.SA_aggregate_datacollection.labels = [];
                        _this.SA_aggregate_datacollection.datasets[0].data = [];

                        _this.ergo_eval_response = false;
                        _this.EE_aggregate_datacollection.labels = [];
                        _this.EE_aggregate_datacollection.datasets[0].data = [];

                        if (decodedJson.records.saRecordsCSV && decodedJson.total_responses > 0) {
                            _this.self_assessment_responseCSV = decodedJson.records.saRecordsCSV;
                            _this.self_assessment_response = decodedJson.records.saRecords;

                            _this.ergo_eval_responseCSV = [];
                            _this.ergo_eval_response = [];

                            Object.keys(_this.self_assessment_response).forEach(key => {
                                let item = _this.self_assessment_response[key];
                                _this.SA_aggregate_datacollection.labels.push(item.month);
                                _this.SA_aggregate_datacollection.datasets[0].data.push(item.count);
                                checkAggregateCounts += Number(item.count);
                            });
                            setTimeout(() => {
                                _this.$refs.SAAggregateResponses.renderChart(_this.SA_aggregate_datacollection, _this.chart_options);
                            }, 100);
                            _this.total_SA_responses = decodedJson.total_responses;
                        } else if (decodedJson.records.eeRecordsCSV && decodedJson.total_responses > 0) {
                            _this.ergo_eval_responseCSV = decodedJson.records.eeRecordsCSV;
                            _this.ergo_eval_response = decodedJson.records.eeRecords;

                            _this.self_assessment_responseCSV = [];
                            _this.self_assessment_response = [];

                            Object.keys(_this.ergo_eval_response).forEach(key => {
                                let item = _this.ergo_eval_response[key];
                                _this.EE_aggregate_datacollection.labels.push(item.month);
                                _this.EE_aggregate_datacollection.datasets[0].data.push(item.count);
                                checkAggregateCounts += Number(item.count);
                            });

                            setTimeout(() => {
                                _this.$refs.EEAggregateResponses.renderChart(_this.EE_aggregate_datacollection, _this.chart_options);
                            }, 100);

                            _this.total_EE_responses = decodedJson.total_responses;
                        }

                        if (checkAggregateCounts > 0) {
                            _this.hideAggregateResponseDownloadBtn = true;
                        }
                        else {
                            _this.hideAggregateResponseDownloadBtn = false;
                        }
                    })
            }
            else {
                _this.tokenExpired();
            }
        },
        getResponseBysearch() {
            this.currentPage = 1;
            this.listConfig.limit = this.itemsPerPage;
            this.getAllResponses();
        },
        getAllResponses() {
            if (localStorage.getItem("userToken")) {
                var _this = this;
                _this.oldSelectedCheckbox = this.listConfig.search_status;
                _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/all-followup-pagination",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: _this.encodeAPIRequestWithImages(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.data);
                        _this.response_data = decodedJson.allResponses;
                        _this.data_length = decodedJson.counts;
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                    })
                    .catch(({ response }) => {
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                        _this.$toast.error(response.data.error, {
                            position: "top-right",
                            duration: 5000,
                        });
                        if (response.status == 401) {
                            _this.$router.push({ name: "login" });
                        }
                    });
            } else {
                this.tokenExpired();
            }
        },
        getFurtherAssistanceData() {
            var _this = this;
            if (localStorage.getItem("userToken")) {
                let config = {
                    method: "post",
                    url: process.env.VUE_APP_API_URL + "/api/further-assistance-data",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                    data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
                };
                _this
                    .axios(config)
                    .then(({
                        data
                    }) => {
                        _this.further_assistance_requested = false;
                        if (data.success) {
                            _this.hideFurtherAssistanceDownloadBtn = true;

                            var decodedJson = _this.decodeAPIResponse(data.data);
                            _this.further_assistance_requested = decodedJson.records.further_assistance_requested;
                            _this.further_assistance_csv = decodedJson.records.further_assistance_csv;
                        } else {
                            _this.hideFurtherAssistanceDownloadBtn = false;
                            _this.further_assistance_requested = [];
                            _this.further_assistance_csv = [];
                        }
                    });
            } else {
                _this.tokenExpired();
            }
        },
        getSingleFollowupDetails(id) {
            var _this = this;
            let config = {
                method: "get",
                url: process.env.VUE_APP_API_URL + "/api/get-followup/" + id,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
            };
            _this
                .axios(config)
                .then(({ data }) => {
                    if (data.success) {
                        _this.followup = data.followup_record;
                    }
                });
        },
        filterMainData() {
            if (localStorage.getItem("userToken")) {
                //Self-Assessment Helpful Filter
                this.listConfig.SAHelpful_sdate = this.listConfig.start_date;
                this.listConfig.SAHelpful_edate = this.listConfig.end_date;
                this.listConfig.SAHelpful_regionBy = this.listConfig.regionBy;
                this.getSAHelpfulChartData();
                //Ergo Eval Helpful Filter
                this.listConfig.EEHelpful_sdate = this.listConfig.start_date;
                this.listConfig.EEHelpful_edate = this.listConfig.end_date;
                this.listConfig.EEHelpful_regionBy = this.listConfig.regionBy;
                this.getEEHelpfulChartData();
                //Reported Improvement Helpful Filter
                this.listConfig.improvement_sdate = this.listConfig.start_date;
                this.listConfig.improvement_edate = this.listConfig.end_date;
                this.listConfig.improvement_regionBy = this.listConfig.regionBy;
                this.getReportedImprovementChartData();
                //Aggregate Responses Filter
                this.listConfig.aggregate_res_sdate = this.listConfig.start_date;
                this.listConfig.aggregate_res_edate = this.listConfig.end_date;
                this.listConfig.aggregate_res_regionBy = this.listConfig.regionBy;
                this.getAggregateResponseChartData();
                //Followup Feedback Table Filter
                this.listConfig.feedback_sdate = this.listConfig.start_date;
                this.listConfig.feedback_edate = this.listConfig.end_date;
                this.listConfig.feedback_regionBy = this.listConfig.regionBy;
                this.getAllResponses();

                this.getFurtherAssistanceData();
            }
            else {
                this.tokenExpired();
            }
        },
        updateDatePicker(comeFrom) {
            $.fn.dataTable.ext.search.push(function (settings, data) {
                var min = $(".min-date-value").html();
                var max = $(".max-date-value").html();
                var createdAt = data[0] || 0; // Our date column in the table
                if (min == "" || max == "" || (moment(createdAt).isSameOrAfter(min) && moment(createdAt).isSameOrBefore(max))) {
                    return true;
                }
                return false;
            });
            if (comeFrom == "Main") {
                this.filterMainData();
            }
        },
        changeSAStartDate() {
            this.$nextTick(() => {
                this.isDisabledSAHelpful = false;
                this.disabledDates = {
                    to: new Date(this.listConfig.SAHelpful_sdate - 8640000),
                    from: new Date(Date.now()),
                };
                if (this.listConfig.SAHelpful_edate != "" && this.listConfig.SAHelpful_edate != null) {
                    this.updateDatePicker("");
                }
            });
        },
        changeSAEndDate() {
            this.$nextTick(() => {
                this.updateDatePicker("");
            });
        },
        changeEEStartDate() {
            this.$nextTick(() => {
                this.isDisabledEEHelpful = false;
                this.disabledDates = {
                    to: new Date(this.listConfig.EEHelpful_sdate - 8640000),
                    from: new Date(Date.now()),
                };
                if (this.listConfig.EEHelpful_edate != "" && this.listConfig.EEHelpful_edate != null) {
                    this.updateDatePicker("");
                }
            });
        },
        changeEEEndDate() {
            this.$nextTick(() => {
                this.updateDatePicker('');
            });
        },
        changeRIStartDate() {
            this.$nextTick(() => {
                this.isDisabledImprovement = false;
                this.disabledDates = {
                    to: new Date(this.listConfig.improvement_sdate - 8640000),
                    from: new Date(Date.now()),
                };
                if (this.listConfig.improvement_edate != "" && this.listConfig.improvement_edate != null) {
                    this.updateDatePicker("");
                }
            });
        },
        changeRIEndDate() {
            this.$nextTick(() => {
                this.updateDatePicker('');
            });
        },
        changeARStartDate() {
            this.$nextTick(() => {
                this.isDisabledAggregateRes = false;
                this.disabledDates = {
                    to: new Date(this.listConfig.aggregate_res_sdate - 8640000),
                    from: new Date(Date.now()),
                };
                if (this.listConfig.aggregate_res_edate != "" && this.listConfig.aggregate_res_edate != null) {
                    this.updateDatePicker("");
                }
            });
        },
        changeAREndDate() {
            this.$nextTick(() => {
                this.updateDatePicker('');
            });
        },
        // changeStartDate() {
        //     this.$nextTick(() => {
        //         this.isDisabled = false;
        //         this.disabledDates = {
        //             to: new Date(this.listConfig.start_date - 8640000),
        //             from: new Date(Date.now()),
        //         };
        //         if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
        //             this.updateDatePicker("Main");
        //         }
        //     });
        // },
        changeStartDate(newStartDate) {
            this.$nextTick(() => {
                const startDate = new Date(newStartDate);
                const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

                if (endDate && startDate > endDate) {
                    this.$toast.error('"From" date cannot be greater than the "To" date.', {
                        position: "top-right",
                        duration: 5000,
                    });
                    this.listConfig.start_date = this.previousStartDate;
                } else {
                    this.previousStartDate = newStartDate;

                    // Old changeStartDate code
                    this.isDisabled = false;
                    this.disabledDates = {
                        to: new Date(this.listConfig.start_date - 8640000),
                        from: new Date(Date.now()),
                    };
                    if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
                        this.updateDatePicker("Main");
                    }
                }
            });
        },
        changeEndDate() {
            this.$nextTick(() => {
                this.updateDatePicker("Main");
            });
        },
        customFormatter(date) {
            return moment(date).format("MMM DD, YYYY");
        },
        async exportAllData() {
            const confirmDownload = await this.$refs.confirmDialogue.show({
                title: "Download",
                message: "Are you sure you want to download?",
                okButton: "Download",
            });

            if (confirmDownload && localStorage.getItem("userToken")) {
                var _this = this;
                _this.$parent.$refs.fullpageloader.is_full_page_loader = true;

                let config = {
                    method: "post",
                    url:
                        process.env.VUE_APP_API_URL +
                        "/api/employee-feedback-download-all",
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                            "userToken"
                        )}`,
                    },
                    data: { request_data: _this.encodeAPIRequestWithImages(_this.listConfig) },
                };

                _this.axios(config)
                    .then(({ data }) => {
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                        var downloadResponse = _this.decodeAPIResponse(data.data);

                        var filterExport = [];
                        if (downloadResponse) {
                            downloadResponse.forEach(function (responseData) {
                                filterExport.push({
                                    "FIRST NAME": responseData.first_name,
                                    "LAST NAME": responseData.last_name,
                                    "EMAIL ADDRESS": responseData.user_email,
                                    "REGION": responseData.region,
                                    "DATE OF FOLLOW-UP": responseData.formated_date,
                                    "NOTE": responseData.reported_improvement_feedback,
                                    "STATUS": responseData.status,
                                });
                            });
                            data = filterExport;
                        }
                        const fileName = "EmployeeFeedback";
                        const exportType = exportFromJSON.types.csv;

                        if (data) exportFromJSON({ data, fileName, exportType });
                    })
                    .catch(({ response }) => {
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                        _this.$toast.error(response.data.error, {
                            position: "top-right",
                            duration: 5000,
                        });
                        if (response.status == 401) {
                            _this.$router.push({ name: "login" });
                        }
                    });
            }
        },
        async updateBulkStatus($event) {
            if (this.reportCheckbox.length && $event != null) {
                const confirmUpdate = await this.$refs.confirmDialogue.show({
                    title: "Update Status",
                    message: "Are you sure you want to update status?",
                    okButton: "Update",
                });

                if (confirmUpdate) {
                    this.reportCheckboxArr.status = $event;
                    this.reportCheckboxArr.checkboxes = this.reportCheckbox;
                    if (localStorage.getItem("userToken")) {
                        var _this = this;
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
                        let config = {
                            method: "post",
                            url:
                                process.env.VUE_APP_API_URL +
                                "/api/client-followup-status-bulk-edit",
                            headers: {
                                Authorization: `Bearer ${window.localStorage.getItem(
                                    "userToken"
                                )}`,
                            },
                            data: { request_data: this.encodeAPIRequest(this.reportCheckboxArr) },
                        };
                        _this
                            .axios(config)
                            .then(({ data }) => {
                                if (data.success) {
                                    _this.$toast.success(data.message, {
                                        position: "top-right",
                                    });
                                    this.listConfig.search_status = null;
                                    this.reportCheckbox = [];
                                    this.getResponseBysearch();
                                    this.selectAll = false;
                                } else {
                                    _this.$toast.error(data.message, {
                                        position: "top-right",
                                    });
                                }
                                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                            })
                            .catch(({ response }) => {
                                console.log(response);
                                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                                _this.$toast.error(response.data.error, {
                                    position: "top-right",
                                    duration: 5000,
                                });
                                if (response.status == 401) {
                                    _this.$router.push({ name: "login" });
                                }
                            });
                    } else {
                        this.tokenExpired();
                    }
                }
            } else {
                this.reportCheckbox.length = 0;
                this.getResponseBysearch();
            }
        },
    },
}
</script>
